import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { API_URL } from "@/_plugins/axios";
import laravelPermissions from "@/_plugins/laravelPermissions";
import vClickOutside from "v-click-outside";
import Toast from "vue-toastification";
// Import the CSS or use your own!
import "vue-toastification/dist/index.css";
import i18n from "@/_plugins/i18n";
// import QrcodeVue from "qrcode.vue";
// Vue.component("qrcode-vue", QrcodeVue);
// import VueQr from "vue-qr";
// import QrcodeVue from 'qrcode.vue'
// Vue.component("qrcode-vue", QrcodeVue);

import VueDatePicker from "@mathieustan/vue-datepicker";
import "@mathieustan/vue-datepicker/dist/vue-datepicker.min.css";

Vue.use(VueDatePicker);

import VueFileAgent from "vue-file-agent";
import "vue-file-agent/dist/vue-file-agent.css";

Vue.use(VueFileAgent);

import VTooltip from "v-tooltip";

Vue.use(VTooltip);
// Websockets

// import Echo from "laravel-echo";

window.Pusher = require("pusher-js");
import Pusher from "pusher-js";

// window.Echo = new Echo({
//   broadcaster: "pusher",
//   key: process.env.VUE_APP_WEBSOCKETS_KEY,
//   wsHost: process.env.VUE_APP_WEBSOCKETS_SERVER,
//   wsPort: 6001,
//   forceTLS: false,
//   disableStats: true,
// });

window.pusher = new Pusher("f57a4b5a9148caecee86", {
  cluster: "eu",
});

Vue.use(require("vue-moment"));

Vue.filter("truncate", function (value, limit) {
  if (!value) return "";
  value = value.toString();
  return value && value.length > limit
    ? value.substring(0, limit) + "..."
    : value;
});

Vue.mixin({
  methods: {
    exportExcel(route, fileName, params) {
      store.commit("toggleExporting");
      // let vm = this;
      // this.$toast.info("Generišem izvještaj...");
      API_URL.get(route, {
        responseType: "blob",
        params: params,
      })
        .then((response) => {
          store.commit("toggleExporting");
          this.$toast.success("Excel generisan");
          let fileLink = document.createElement("a");

          fileLink.href = window.URL.createObjectURL(new Blob([response.data]));
          fileLink.setAttribute(
            "download",
            `${fileName}- ${this.$moment().format("D-M-Y")}.xlsx`
          );
          document.body.appendChild(fileLink);

          fileLink.click();
        })
        .catch(() => {
          store.commit("toggleExporting");
        });
    },
  },
});

const options = {
  // You can set your default options here
  transition: "Vue-Toastification__bounce",
  maxToasts: 2,
  newestOnTop: true,
  position: "top-right",
  timeout: 3000,
  closeOnClick: true,
  pauseOnFocusLoss: true,
  pauseOnHover: true,
  draggable: true,
  draggablePercent: 0.6,
  showCloseButtonOnHover: false,
  hideProgressBar: true,
  closeButton: "button",
  icon: true,
  rtl: false,
};

Vue.use(Toast, options);

Vue.use(vClickOutside);
import "./_style/style.scss";
import "./_style/_colors.scss";

import { BootstrapVue, IconsPlugin } from "bootstrap-vue";

// Import Bootstrap an BootstrapVue CSS files (order is important)
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue);
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin);
Vue.use(laravelPermissions);

// Components import
import Footer from "./components/Footer/footer.vue";
import Sidebar from "./components/Sidebar/sidebar.vue";
import Header from "./components/Header/header.vue";

//Global components
Vue.component("app-footer", Footer);
Vue.component("app-sidebar", Sidebar);
Vue.component("app-header", Header);

import Skeleton from "vue-loading-skeleton";

Vue.use(Skeleton);

new Vue({
  router,
  store,
  API_URL,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
