import Vue from "vue";
import VueRouter from "vue-router";
import SimpleLayout from "@/Layouts/SimpleLayout";
import FullLayout from "@/Layouts/FullLayout";
import store from "../store";
import i18n from "@/_plugins/i18n";

Vue.use(VueRouter);

const routes = [
  {
    path: "/login",
    component: SimpleLayout,
    children: [
      {
        path: "",
        name: "Login",
        component: () =>
          import(/* webpackChunkName: "home" */ "../views/Login/login.vue"),
        meta: {
          metaName: i18n.tc("navbar.login"),
          requiresVisitor: true,
        },
      },
    ],
  },
  {
    path: "",
    component: FullLayout,
    children: [
      {
        path: "/",
        name: "Home",
        meta: {
          metaName: i18n.tc("navbar.home"),
          requiresAuth: true,
        },
        component: () =>
          import(
            /* webpackChunkName: "dashboard" */ "../views/Dashboard/dashboard.vue"
          ),
      },
      {
        path: "/equipment-document",
        name: "EquipmentDocument",
        meta: {
          metaName: i18n.tc("navbar.equipment_document"),
          requiresAuth: true,
        },
        component: () =>
          import(
            /* webpackChunkName: "equipmentDocument" */ "../views/EquipmentDocument/equipmentDocument.vue"
          ),
      },
      {
        path: "/equipment-document-history",
        name: "EquipmentDocumentHistory",
        meta: {
          metaName: i18n.tc("navbar.equipment_document"),
          requiresAuth: true,
        },
        component: () =>
          import(
            /* webpackChunkName: "equipmentDocument" */ "../views/EquipmentDocumentHistory/equipmentDocumentHistory.vue"
          ),
      },
      {
        path: "/reports",
        name: "Reports",
        meta: {
          metaName: i18n.tc("navbar.reports"),
          requiresAuth: true,
          super_admin: false,
        },
        component: () =>
          import(
            /* webpackChunkName: "reports" */ "../views/Reports/reports.vue"
          ),
      },
      {
        path: "/users",
        name: "Users",
        meta: {
          metaName: i18n.tc("navbar.users"),
          requiresAuth: true,
        },
        component: () =>
          import(/* webpackChunkName: "users" */ "../views/Users/users.vue"),
      },
      {
        path: "/equipment",
        name: "Equipment",
        meta: {
          metaName: i18n.tc("navbar.equipment"),
          requiresAuth: true,
        },
        component: () =>
          import(
            /* webpackChunkName: "equipment" */ "../views/Equipment/equipment.vue"
          ),
      },
      {
        path: "/my-equipment",
        name: "MyEquipment",

        meta: {
          metaName: i18n.tc("navbar.my_equipment"),
          requiresAuth: true,
        },
        component: () =>
          import(
            /* webpackChunkName: "my-equipment" */ "../views/Equipment/my-equipment.vue"
          ),
      },
      {
        path: "/equipment/:id",
        name: "EquipmentDetails",
        meta: {
          metaName: i18n.tc("navbar.equipment"),
          requiresAuth: true,
        },
        component: () =>
          import(
            /* webpackChunkName: "equipment-details" */ "../views/Equipment/equipment-details.vue"
          ),
      },
      {
        path: "/new-equipment-requests",
        name: "NewEquipment",
        meta: {
          metaName: i18n.tc("navbar.new_equipment_requests"),
          requiresAuth: true,
        },
        component: () =>
          import(
            /* webpackChunkName: "equipment" */ "../views/NewEquipmentReq/newEquipmentReq.vue"
          ),
      },
      {
        path: "/repair-equipment-requests",
        name: "RepairEquipment",
        meta: {
          metaName: i18n.tc("navbar.repair_equipment_requests"),
          requiresAuth: true,
        },
        component: () =>
          import(
            /* webpackChunkName: "equipment" */ "../views/RepairEquipmentReq/repairEquipmentReq.vue"
          ),
      },
      {
        path: "/request-new-equipment",
        name: "RequestNewEquipment",
        meta: {
          metaName: i18n.tc("navbar.request_new_equipment"),
          requiresAuth: true,
        },
        component: () =>
          import(
            /* webpackChunkName: "equipment" */ "../views/NewEquipment/newEquipment.vue"
          ),
      },
      {
        path: "/request-repair-equipment",
        name: "RequestRepairEquipment",
        meta: {
          metaName: i18n.tc("navbar.request_repair_equipment"),
          requiresAuth: true,
        },
        component: () =>
          import(
            /* webpackChunkName: "equipment" */ "../views/RepairEquipment/repairEquipment.vue"
          ),
      },
      {
        path: "/category",
        name: "Category",
        meta: {
          metaName: "Kategorije",
          requiresAuth: true,
        },
        component: () =>
          import(
            /* webpackChunkName: "equipment" */ "../views/Category/category.vue"
          ),
      },
      {
        path: "/subcategory",
        name: "Subcategory",
        meta: {
          metaName: "Potkategorije",
          requiresAuth: true,
        },
        component: () =>
          import(
            /* webpackChunkName: "equipment" */ "../views/Subcategory/subcategory.vue"
          ),
      },
      {
        path: "/service",
        name: "Service",
        meta: {
          metaName: "Servis",
          requiresAuth: true,
        },
        component: () =>
          import(
            /* webpackChunkName: "service" */ "../views/Service/service.vue"
          ),
      },
      {
        path: "/service-history",
        name: "ServiceHistory",
        meta: {
          metaName: "Istorija servisa",
          requiresAuth: true,
        },
        component: () =>
          import(
            /* webpackChunkName: "servicehistory" */ "../views/ServiceHistory/service.vue"
          ),
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  linkActiveClass: "active",
  routes, // short for `routes: routes`
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
});
router.beforeEach((to, from, next) => {
  document.title = `Inventory App | ${to.meta.metaName}`;
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!store.getters["auth/loggedIn"]) {
      next({
        path: "/login",
      });
    } else {
      if (
        to.matched.some(
          (record) =>
            record.meta.super_admin &&
            store.getters["auth/user"].roles[0] !== "super-admin"
        )
      ) {
        next({ path: "/" });
      }
      next();
    }
  } else if (to.matched.some((record) => record.meta.requiresVisitor)) {
    if (store.getters["auth/loggedIn"]) {
      next({
        path: "/home",
      });
    } else {
      next();
    }
  }
});

export default router;

// import App from "./app.vue";
// import PageNotFound from "./PageNotFound/pageNotFound.vue";
// import Login from "./Login/service.vue";
// import SimpleLayout from "./_components/SimpleLayout/simpleLayout.vue";
// import FullLayout from "./_components/FullLayout/fullLayout.vue";
// import Home from "./Home/home.vue";
// import Users from "./Users/users.vue";

//
// let routes = [
//   {
//     path: "/:lang",
//     component: App,
//     children: [
//       {
//         path: "",
//         component: SimpleLayout,
//         children: [
//           {
//             path: "login",
//             component: Login,
//             meta: {
//               requiresVisitor: true,
//             },
//           },
//         ],
//       },
//       {
//         path: "",
//         component: FullLayout,
//         children: [
//           {
//             path: "home",
//             component: Home,
//           },
//           {
//             path: "users",
//             component: Users,
//           },
//         ],
//         meta: {
//           requiresVisitor: true,
//         },
//       },
//       {
//         path: "*",
//         component: PageNotFound,
//         name: "404",
//         meta: {
//           requiresVisitor: true,
//         },
//       },
//     ],
//   },
//   { path: "*", redirect: "/" + default_lang + "/login" },
// ];
//
// export default routes;
