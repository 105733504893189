import Vue from "vue";
import Vuex from "vuex";
import auth from "@/store/modules/auth.module";
import { API_URL } from "../_plugins/axios";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    notifications: [],
    exporting: false,
  },
  getters: {
    notifications: (state) => state.notifications,
    exporting: (state) => state.exporting,
  },
  mutations: {
    toggleExporting: (state) => (state.exporting = !state.exporting),
    incrementNotification: (state, val) => (state.notifications = val),
    removeNotification: (state, val) => {
      API_URL.delete(`mark-as-read/${val}`).then(() => {
        state.notifications = state.notifications.filter((el) => {
          return el.id !== val;
        });
      });
    },
    clearNotis: (state) => (state.notifications = []),
  },
  actions: {
    addNotification({ commit }, val) {
      commit("incrementNotification", val);
    },
    removeNotification({ commit }, val) {
      commit("removeNotification", val);
    },
    clearNotifications({ commit }) {
      commit("clearNotis");
    },
    toggleExporting({ commit }) {
      commit("toggleExporting");
    },
  },
  modules: {
    auth,
  },
});
